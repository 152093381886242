<template>
	<el-row style="">
		<div ref="table">
			<el-row style="width:1350px;margin:50px auto;line-height:30px; background:white;margin-top:20px; padding:0 53px;box-shadow: 0px 0px 6px #c4c4c4;">
				<el-col :span="24" style="margin-top:20px;margin-bottom:20px;">
					<el-col :span="2">我的购物车</el-col>
					<el-col :span="22" class="hengxian"></el-col>
				</el-col>
				<el-col :span="24" style="margin-top:20px;">
					<el-col :span="2"><input type="checkbox" class="selBtn" :data-id="0" @click="dianji()">全选</el-col>
					<el-col :span="22" style="color:blue;">
						<el-popconfirm confirmButtonText='删除' cancelButtonText='不用了' icon="el-icon-info" iconColor="red" title="确定移除该物品？"
						 @onConfirm="delete_list_all()">
							<span style="color:rgb(203, 80, 49);cursor: pointer;font-size: 14px;" slot="reference">批量删除</span>
						</el-popconfirm>
					</el-col>
				</el-col>
				<el-col :span="24" v-if="dataList.length==0" style="margin:20px 0;height:180px;border-top:1px solid #efefef;text-align: center;color: red;">
					暂无商品
				</el-col>
				<el-col :span="24" v-for="itme in dataList" style="margin:20px 0;height:140px;border-top:1px solid #efefef;">
					<el-col :span="2" style="line-height: 180px;">
						<input type="checkbox" class="selBtn" :data-id="itme.resource_id" @click="input_ele(itme.resource_id,itme.goldcoin)"
						 :checked="isshow">
						选择
					</el-col>
					<el-col :span="4" style="padding: 20px;"><img style="width: 100%;" @click="resources_url(itme.resource_id)" :src="common.retun_img(itme.thumbnail)"
						 alt=""></el-col>
					<el-col :span="10">
						<el-col :span="24" style="line-height: 80px;font-size: 14px;">{{itme.name}}</el-col>
					</el-col>
					<el-col :span="4" style="line-height: 80px;">￥<span>{{itme.goldcoin}}</span></el-col>
					<el-col :span="4" style="line-height: 80px;">
						<span style="cursor: pointer;font-size: 14px;" @click='click_Collection(itme)'>加入收藏 </span>
						&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
						<el-popconfirm confirmButtonText='删除' cancelButtonText='不用了' icon="el-icon-info" iconColor="red" title="确定移除该物品？"
						 @onConfirm="delete_list(itme.resource_id)">
							<span style="color:rgb(203, 80, 49);cursor: pointer;font-size: 14px;" slot="reference">删除</span>
						</el-popconfirm>
					</el-col>
				</el-col>
				<el-col :span="24" style="margin:20px 0;border-top:1px solid #efefef;text-align: right;padding: 20px;">
					共需结算{{Number_shu}}金币
					<el-button type="primary" @click="settlement()">结算</el-button>
				</el-col>
			</el-row>
		</div>

		<el-dialog title="提示" :visible.sync="dialogVisible" width="30%">
			<el-select v-model="value" placeholder="请选择收藏的文件夹">
				<el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value">
				</el-option>
			</el-select>
			<el-input style="width:320px; margin-right: 10px; margin-top: 20px;" placeholder="请输入内容" maxlength=8 v-model="add_ProjectList_input"></el-input>
			<el-button @click='addfolder'>添加文件夹</el-button>
			<span slot="footer" class="dialog-footer">
				<el-button @click="dialogVisible = false">取 消</el-button>
				<el-button type="primary" @click="selection_box()">确 定</el-button>
			</span>
		</el-dialog>
		<login v-show="showlogin" @closeDialog="closeDialog"></login>
	</el-row>
</template>
<script>
	import {
		requestAddlist,
		requestDeletelist,
		requestDeletelistall,
		requestdirectory_list,
		requestaccount_collect_add,
		requestAccount_cart,
		requestdirectory_add
	} from "../api/request";
	import login from "@/views/Login.vue";
	export default {
		name: "myshowshare",
		components: {
			login,
		},
		data() {
			return {
				dataList: [],
				isshow: false,
				data_listid: [],
				data_delete: [],
				//收藏弹出框
				dialogVisible: false,
				//select选择框
				options: [],
				//选择框绑定
				value: '',
				//资源数据
				resource_data: '',
				showlogin: '',
				add_ProjectList_input: '',
				Child_list: '',
				current_select_level1_item: null,
				Number_shu: 0
			}
		},
		methods: {
			//登陆窗口
			closeDialog(data) {
				this.showlogin = data;
			},
			//购物车列表
			init: function() {
				requestAddlist().then(res => {
					this.dataList = res.ret;
					for (let i = 0; i < res.ret.length; i++) {
						this.data_listid.push(res.ret[i].resource_id)
					}
				})
			},
			//结算金币
			settlement: function() {
				//data = {“ token”：“”，“ value_array”：[{“ resource_id”：int，“ goldcoin”：int，“ owner_id”：int}，{“ resource_id”：int，“ goldcoin”：int，“ owner_id “：int}]
				let data_this = this,
					ayy_ay = [],
					ayy_goldcoin = 0;
				let storage_nav = this.Account.GetStorage();
				if (this.data_delete.length != 0) {
					for (let i = 0; i < data_this.data_delete.length; i++) {
						for (let b = 0; b < data_this.dataList.length; b++) {
							if (data_this.data_delete[i] == data_this.dataList[b].resource_id) {
								let obj = {
									resource_id: data_this.dataList[b].resource_id,
									goldcoin: data_this.dataList[b].goldcoin,
									owner_id: data_this.dataList[b].account_id,
								}
								ayy_ay.push(obj)
								ayy_goldcoin += Number(data_this.dataList[b].goldcoin)
							}
						}
					}
					if (storage_nav.goldcoin < ayy_goldcoin) {
						data_this.$message({
							message: '金币不足，请前去充值！',
							type: 'warning'
						});
						return false
					}
					this.$confirm('确认是否结算当前选着的商品？提示：您当前还有'+storage_nav.goldcoin+'币', '提示', {
						confirmButtonText: '确定',
						cancelButtonText: '取消',
						type: 'warning'
					}).then(() => {
						requestAccount_cart(ayy_ay).then(res => {
							if (res.code == 0) {
								data_this.$message({
									message: '您购买的模型可以前去BIM-FILM中使用，动画可以到-我的钱包中下载',
									type: 'success'
								});
								data_this.Number_shu = 0;
								data_this.common.coin_calculation(1, ayy_goldcoin)
								data_this.init()
								data_this.eliminate();
								data_this.$router.push('MyWallet')
							}
						})
					}).catch(() => {
						this.$message({
							type: 'info',
							message: '已取消购买'
						});
					});
				} else {
					data_this.$message({
						message: '请选择商品！',
						type: 'warning'
					});
				}
			},
			//点击收藏函数
			click_Collection(_this) {
				if (this.Account.GetSession() == null) {
					this.showlogin = true;
					return
				}
				this.dialogVisible = true;
				this.resource_data = _this
				let data = {
					parent_id: 0
				};
				requestdirectory_list(data).then(res => {
					this.current_select_level1_item = res.ret[1];
					// 一级列表赋值
					let datas = {
						parent_id: res.ret[1].id
					};
					requestdirectory_list(datas).then(res => {
						this.Child_list = res.ret;
						this.options = [];
						//二级列表赋值
						for (let i = 0; i < res.ret.length; i++) {
							this.options.push({
								value: res.ret[i].node_id,
								label: res.ret[i].name
							})
						}
					});
				});
			},
			//添加文件夹
			addfolder() {
				if (this.add_ProjectList_input == '') {
					this.$message({
						message: '请填写项目名称',
						type: 'warning'
					});
					return
				};
				//获取最大的sequence
				let max_sequence = 0;
				for (let h = 0; h < this.Child_list.length; h++) {

					if (this.Child_list[h].sequence > max_sequence) {
						max_sequence = this.Child_list[h].sequence;
					}
					if (this.Child_list[h].name == this.add_ProjectList_input) {

						this.$message({
							message: '不可添加重复文件夹名称',
							type: 'warning'
						});
						return
					}
				};
				let sequence = max_sequence + 1;
				let node_idstr = this.common.retun_getnodeid(
					this.current_select_level1_item.node_id,
					2,
					sequence
				);
				let addData = {
					name: this.add_ProjectList_input,
					parent_id: this.current_select_level1_item.id,
					level: 2,
					sequence: sequence,
					node_id: node_idstr,
					parent_node_id: this.current_select_level1_item.node_id
				};
				//添加请求
				let _this = this;
				requestdirectory_add(addData).then((res) => {
					if (res.code == 0) {
						this.$message({
							message: '添加成功',
							type: 'success'
						});
						this.add_ProjectList_input = ''
					}
					this.dialogVisible_add = false;
					let data = {
						parent_id: 0
					};
					requestdirectory_list(data).then(res => {
						// 一级列表赋值
						let datas = {
							parent_id: res.ret[1].id
						};
						requestdirectory_list(datas).then(res => {
							this.Child_list = res.ret;
							this.options = [];
							//二级列表赋值
							for (let i = 0; i < res.ret.length; i++) {
								this.options.push({
									value: res.ret[i].node_id,
									label: res.ret[i].name
								})
							}
						});
					});
				});

			},
			//清除选择
			eliminate() {
				let checkDom = this.$refs.table.getElementsByClassName("selBtn");
				console.log(checkDom)
				for (let i = 0; i < checkDom.length; i++) {
					this.$refs.table.getElementsByClassName("selBtn")[i].checked = false
				}
			},
			selection_box() {
				if (this.value == '') {
					this.$message({
						type: 'info',
						message: `请选择文件夹`
					});
					return
				}
				this.dialogVisible = false;
				// data={"token":"","resource_id":int,"node_id":""}
				let data = {
					"resource_id": this.resource_data.resource_id,
					"node_id": this.value
				}
				requestaccount_collect_add(data).then(res => {
					console.log(res)
				})
			},
			//单个删购物车
			delete_list: function(id) {
				console.log(id)
				let data_this = this;
				requestDeletelist(id).then(res => {
					console.log(res)
					data_this.$message({
						message: '删除成功！',
						type: 'success'
					});
					data_this.init()
					data_this.eliminate();

				})
			},
			//多个删购物车
			delete_list_all: function() {
				let data_this = this;
				requestDeletelistall(this.data_delete).then(res => {
					if(res.code==0){
						this.Number_shu=0;
						data_this.$message({
							message: '删除成功！',
							type: 'success'
						});
						data_this.init()
						data_this.eliminate();
					}
					

				})
			},
			//全选
			dianji: function() {
				this.isshow = event.target.checked;
				event.target.checked ? this.data_delete = this.data_listid : this.data_delete = [];
				console.log(this.dataList, event.target.checked);
				if (event.target.checked) {
					let number = null
					for (let i = 0; i < this.dataList.length; i++) {
						number += this.dataList[i].goldcoin;
					}
					this.Number_shu = number;
				} else {
					this.Number_shu = 0
				}
			},
			//单选
			input_ele: function(resource_id, goldcoin) {
				event.target.checked ? this.Number_shu += goldcoin : this.Number_shu -= goldcoin;
				event.target.checked ? this.data_delete.push(resource_id) : this.data_delete.splice(this.data_delete.indexOf(
					resource_id), 1);
				console.log(this.data_delete)
			},
			//跳转
			resources_url: function(e) {
				this.$router.push({
					path: "/ModeResources",
					query: {
						id: e
					}
				});
			}
		},
		mounted() {
			this.init()
		}
	};
</script>
<style scoped>
	.hengxian {
		height: 1px;
		border-top: 1px solid rgb(203, 80, 49);
		margin-top: 15px;
	}
	.el-button--primary{
		background-color:rgb(203, 80, 49);
		border-color:rgb(203, 80, 49);
	}
</style>
